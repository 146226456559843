<script>
  export let fill = false;
  export let stroke = false;
  export let white = false;
</script>

<svg
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
  stroke=""
  stroke-width="2"
  stroke-linecap="round"
  stroke-linejoin="round"
  class:fill
  class:stroke
  class:white
  ><path
    d="M16 8a6 6 0 0 1 6 6v7h-4v-7a2 2 0 0 0-2-2 2 2 0 0 0-2 2v7h-4v-7a6 6 0 0 1 6-6z" /><rect
    x="2"
    y="9"
    width="4"
    height="12" /><circle cx="4" cy="4" r="2" /></svg>

<style>
  svg.fill {
    fill: #0078b1;
  }

  svg.stroke {
    stroke: #0078b1;
  }

  svg:not(.fill) {
    fill: none;
  }

  svg:not(.stroke) {
    stroke: none;
  }

  svg.white {
    fill: var(--color-icon);
  }
</style>
