<script>
  import FacebookIcon from './icons/FacebookIcon.svelte';
  import LinkedInIcon from './icons/LinkedInIcon.svelte';
  import TwitterIcon from './icons/TwitterIcon.svelte';
  import WhatsAppIcon from './icons/WhatsAppIcon.svelte';

  export let url = 'https://www.eroonkoronasta.fi';
  export let message = '';

  const space = encodeURIComponent(' ');

  $: urlEncoded = encodeURIComponent(url);
  $: msgEncoded = encodeURIComponent(message);
</script>

<div class="share">
  <span class="label">Share</span>

  <a
    href="https://www.facebook.com/sharer.php?u={urlEncoded}"
    rel="noopener"
    target="_blank">
    <FacebookIcon white />
  </a>

  <a
    href="https://twitter.com/share?url={urlEncoded}&text={msgEncoded}"
    rel="noopener"
    target="_blank">
    <TwitterIcon white />
  </a>

  <a
    href="https://www.linkedin.com/shareArticle?mini=true&url={urlEncoded}&title={msgEncoded}"
    rel="noopener"
    target="_blank">
    <LinkedInIcon white />
  </a>

  <a
    href="https://wa.me/?text={msgEncoded}{space}{urlEncoded}"
    rel="noopener"
    target="_blank">
    <WhatsAppIcon white />
  </a>
</div>

<style>
  .share {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .label {
    text-transform: uppercase;
    font-size: .8rem;
    font-weight: 600;
    color: var(--color-primary);
    margin-right: .4rem;
  }

  a {
    width: 2.5rem;
    padding: .5rem 0;
    margin: 0 .25rem;
    display: inline-block;
    border-radius: 0.25em;
    text-align: center;
    transition: box-shadow 200ms cubic-bezier(0.165, 0.84, 0.44, 1);
    text-decoration: none;
  }

  a:focus {
    box-shadow: 0 0 0 3px var(--color-focus);
    outline: none;
  }



  @media screen and (max-width: 767px) {
    .share {
      
    }

    a {
     
    }
  }

  @media screen and (hover: hover) {
    a:hover {
      background-color: var(--color-hover);
    }
  }
</style>
