<script>
  import * as Pancake from '@sveltejs/pancake';

  const points = JSON.parse(window.__DATA);

  let minx = new Date(points[0].da).getTime();
  let maxx = new Date(points[points.length - 2].da).getTime();
  let miny = 0;
  let maxy = points[points.length - 2].de + 5000;
  let horizontalCount = getHorizontalColumnCount();

  function timestamp(millis) {
    const d = new Date(millis);
    return [d.getMonth() + 1, d.getFullYear().toString().substring(2)].join(
      '/'
    );
  }

  function getHorizontalColumnCount() {
    return window.innerWidth > 1024 ? 10 : 5;
  }

  function handleResize() {
    horizontalCount = getHorizontalColumnCount();
  }

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const pc = (date) => {
    return (100 * (new Date(date).getTime() - minx)) / (maxx - minx);
  };
</script>

<svelte:window on:resize={handleResize} />

<div class="chart">
  <Pancake.Chart x1={minx} x2={maxx} y1={miny} y2={maxy}>
    <Pancake.Grid horizontal count={5} let:value let:last>
      <div class="grid-line horizontal">
        <span class="deaths-label"
          >{value / 1000000}{value === 0 ? '' : 'M'}
          {last ? 'deaths' : ''}</span>
      </div>
    </Pancake.Grid>

    <Pancake.Grid vertical count={horizontalCount} let:value let:first>
      <div class="grid-line vertical">
        {#if !first}
          <span class="year-label">{timestamp(value)}</span>
        {/if}
      </div>
    </Pancake.Grid>

    <Pancake.Svg>
      <Pancake.SvgLine
        data={points}
        x={(d) => new Date(d.da).getTime()}
        y={(d) => Number.parseFloat(d.de)}
        let:d>
        <path class="trend" {d} />
      </Pancake.SvgLine>
    </Pancake.Svg>

    <Pancake.Quadtree
      data={points}
      x={(d) => new Date(d.da).getTime()}
      y={(d) => Number.parseFloat(d.de)}
      let:closest>
      {#if closest}
        <Pancake.Point x={new Date(closest.da).getTime()} y={closest.de} let:d>
          <div class="focus" />
          <div
            class="tooltip"
            style="transform: translate(-{pc(closest.da)}%,0)">
            <strong>{numberWithCommas(Math.round(closest.de))} dead</strong>
            <span>{new Date(closest.da).toLocaleDateString()}</span>
          </div>
        </Pancake.Point>
      {/if}
    </Pancake.Quadtree>
  </Pancake.Chart>
</div>

<style>
  .chart {
    position: absolute;
    top: 6vh;
    bottom: 6vh;
    left: 0;
    width: 100vw;
    --grid-line-color: rgba(255, 255, 255, 0.1);
    --grid-label-color: rgba(255, 255, 255, 0.4);
  }

  path.trend {
    stroke: HSLA(15, 82%, 53%, 75%);
    stroke-linejoin: round;
    stroke-linecap: round;
    stroke-width: 2px;
    fill: none;
  }

  .grid-line {
    position: relative;
    display: block;
  }

  .grid-line.horizontal {
    border-bottom: 1px dashed var(--grid-line-color);
  }

  .grid-line.vertical {
    height: 100%;
    border-left: 1px dashed var(--grid-line-color);
  }

  .grid-line span {
    position: absolute;
    left: 0;
    bottom: 2px;
    line-height: 1;
    font-size: 14px;
    color: var(--grid-label-color);
  }

  .grid-line.horizontal span {
    padding-left: 1rem;
  }

  .grid-line span.deaths-label {
    padding-bottom: 0.5rem;
  }

  .grid-line span.year-label {
    bottom: -1.5rem;
    padding-left: 0;
    transform: translateX(-50%);
  }

  .focus {
    position: absolute;
    width: 10px;
    height: 10px;
    left: -5px;
    top: -5px;
    border: 2px solid white;
    border-radius: 50%;
    box-sizing: border-box;
  }

  .tooltip {
    position: absolute;
    white-space: nowrap;
    width: 8em;
    bottom: 1em;
    font-size: .75rem;
    color: white;
    line-height: 1;
  }

  .tooltip strong {
    font-size: 1rem;
    display: block;
  }
</style>
