<script>
  export let fill = false;
  export let stroke = false;
  export let white = false;
</script>

<svg
  width="24"
  height="24"
  viewBox="0 0 24 24"
  stroke-width="2"
  stroke-linecap="round"
  stroke-linejoin="round"
  class:fill
  class:stroke
  class:white
  ><path
    d="M21 11.5a8.38 8.38 0 0 1-.9 3.8 8.5 8.5 0 0 1-7.6 4.7 8.38 8.38 0 0 1-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 0 1-.9-3.8 8.5 8.5 0 0 1 4.7-7.6 8.38 8.38 0 0 1 3.8-.9h.5a8.48 8.48 0 0 1 8 8v.5z" /></svg>

<style>
  svg.fill {
    fill: #54c761;
  }

  svg.stroke {
    stroke: #54c761;
  }

  svg:not(.fill) {
    fill: none;
  }

  svg:not(.stroke) {
    stroke: none;
  }

  svg.white {
    fill: var(--color-icon);
  }
</style>
