<script>
  export let fill = false;
  export let stroke = false;
  export let white = false;
</script>

<svg
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
  stroke=""
  stroke-width="2"
  stroke-linecap="round"
  stroke-linejoin="round"
  class:fill
  class:stroke
  class:white
  ><path
    d="M23 3a10.9 10.9 0 0 1-3.14 1.53 4.48 4.48 0 0 0-7.86 3v1A10.66 10.66 0 0 1 3 4s-4 9 5 13a11.64 11.64 0 0 1-7 2c9 5 20 0 20-11.5a4.5 4.5 0 0 0-.08-.83A7.72 7.72 0 0 0 23 3z" /></svg>

<style>
  svg.fill {
    fill: #00abee;
  }

  svg.stroke {
    stroke: #00abee;
  }

  svg:not(.fill) {
    fill: none;
  }

  svg:not(.stroke) {
    stroke: none;
  }

  svg.white {
    fill: var(--color-icon);
  }
</style>
