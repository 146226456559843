<script>
  import Chart from './Chart.svelte';
  import Share from './Share.svelte';

  const pandemicStarted = new Date(1573944560617);
  const now = new Date();

  const diffTime = Math.abs(now - pandemicStarted);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
</script>

<div class="hero">
  <div class="count">
    {diffDays}
    <small>days</small>
  </div>
  <h1>
    <small>without</small>

    Global synchronized lockdown
  </h1>

  <footer>
    <Share
      url="https://globalsynchronizedlockdown.org"
      message={`${diffDays} days without global synchronized lockdown, and counting.`} />
  </footer>
</div>

<Chart />

<style>
  .hero {
    text-align: center;
    position: relative;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.15);
    padding: 1rem 2rem;
    border-radius: 24px;
    pointer-events: none;
  }

  footer {
    margin-top: 4rem;
    pointer-events: all;
  }

  .count {
    font-size: 6rem;
    font-weight: 600;
    color: var(--color-primary);
  }

  .count small {
    font-size: 55%;
    text-transform: uppercase;
  }

  h1 {
    /*color: rgba(255, 255, 255, 0.35);*/
    color: var(--color-secondary);
    font-size: 2.2rem;
    text-align: center;
    text-transform: capitalize;
  }

  h1 small {
    display: block;
    font-size: 55%;
  }

  @media screen and (max-width: 767px) {
    .hero {
      padding: 1rem 1.4rem;
    }

    footer {
      margin-top: 2.5rem;
    }

    .count {
      font-size: 3.5rem;
    }
    h1 {
      font-size: 1.1rem;
    }
  }
</style>
