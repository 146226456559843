<script>
  export let fill = false;
  export let stroke = false;
  export let white = false;
</script>

<svg
  width="24"
  height="24"
  viewBox="0 0 24 24"
  stroke-width="2"
  stroke-linecap="round"
  stroke-linejoin="round"
  class:fill
  class:stroke
  class:white
  ><path
    d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z" /></svg>

<style>
  svg.fill {
    fill: #395795;
  }

  svg.stroke {
    stroke: #395795;
  }

  svg:not(.fill) {
    fill: none;
  }

  svg:not(.stroke) {
    stroke: none;
  }

  svg.white {
    fill: var(--color-icon);
  }
</style>
